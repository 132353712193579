<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>
  </section>
</template>
  
 <!-- #################################################################################### -->
 <!-- ###### Sección de Scripts                                                     ###### -->
 <!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from '../../../../components/BlueHeader.vue';


export default {
  name: 'Trade',
  components: { BlueHeader },
  data: () => ({
    itemsHeader: [
      {
        name: 'Portafolio De Ventas',
        link: { name: 'modules.analytics.trade.portafolioDeVentas' },
        rol: Role.Analitica_Comercial_PortafolioDeVentas,
      },
      {
        name: 'Clientes',
        link: { name: 'modules.analytics.trade.clientes' },
        rol: Role.Analitica_Comercial_Clientes,
      },

    ]
  }),

}
</script>
  
 <!-- #################################################################################### -->
 <!-- ###### Sección de Styles                                                      ###### -->
 <!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 100%;
}

</style>